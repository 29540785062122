/* eslint-disable no-undef */
import React from "react"
import StructurePageEriza from "@components/pageCasosdeExito/template/templatePageEriza"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Cliente de Beex: 3eriza [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/casos-de-exito/3eriza/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta name="description" content="3eriza, Contact Center & BPO, ha integrado sus procesos de televentas con Beex, plataforma de Contact Center." />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cliente de Beex: 3eriza" />
      <meta
        property="og:url"
        content={`https://beexcc.com/casos-de-exito/3eriza/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/CasosExito/og-3eriza.jpg`}
      />
      <meta property="og:description" content="3eriza, Contact Center & BPO, ha integrado sus procesos de televentas con Beex, plataforma de Contact Center." />
    </Helmet>
    <StructurePageEriza location={location} />
  </div>
)

export default IndexPage
