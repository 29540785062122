import React from "react"
const DescriptionCustomer = ({ data }) => {
  return (
    <section className={data.class}>
      <section className="intro-descriptioncustomer">
        <section className="descriptionCustomer">
          <p className="text-body-descriptionCustomer descriptionCustomer">
            {data.textbody}{" "}
          </p>
        </section>
        <section className="photo-customer">
          <img
            className="img-customer-casosexito"
            src={data.photo}
            alt="cliente"
            loading="lazy"
          />
        </section>
      </section>
    </section>
  )
}

export default DescriptionCustomer
