import React from "react"
import FotoRimac from "@components/pageCasosdeExito/image/imgPhotoEmpresa/eriza/FotoEriza.png"

//logo de empresas clientes
import Eriza from "@components/pageCasosdeExito/image/logoscustomer/Eriza.svg"

//iconos de modulos
import crm from "@components/pageCasosdeExito/image/iconcards/crm.svg"
import llamadas from "@components/pageCasosdeExito/image/iconcards/llamadas.svg"
import backoffice from "@components/pageCasosdeExito/image/iconcards/backoffice.svg"
import calidad from "@components/pageCasosdeExito/image/iconcards/calidad.svg"

const textintro = (
  <span className="">
    <span className="text-underlined-rimac">
      “3eriza
      <span className="strong-intro-casos"> ofrece productos de diferentes sectores,
      </span> como telecomunicaciones, retail o seguros, apoyándose en
      <span className="strong-intro-casos">
         Beex
      </span>{" "}
      para registrar todo el journey de sus clientes, desde el
      <span className="strong-intro-casos">
          contacto vía telefónico hasta el cierre y validación de las ventas.
   
        ”
      </span>
    </span>
    <br />
    <span className="title-eriza">
      “3eriza
      <span className="strong-intro-casos"> ofrece productos de diferentes sectores,
      </span> como telecomunicaciones, retail o seguros, apoyándose en
      <span className="strong-intro-casos"> Beex</span> para registrar todo el
      journey de sus clientes, desde el
      <span className="strong-intro-casos">
        {" "}
        contacto vía telefónico hasta el cierre y validación de las ventas.”
      </span>
    </span>
  </span>
)

const text = (
  <span>
    3eriza es una empresa proveedora de servicios empresariales, que brinda
    soluciones enfocadas a los negocios, a través de la gestión de procesos,
    empleando capital humano y tecnología.
    <br />
    <br />
    Ayudamos a 3eriza en sus operaciones de Televentas y Telemarketing,
    brindando toda la suite de Beex, donde viven sus clientes y sus
    interacciones, contemplando un proceso completo de televentas, que asegura
    la eficiencia en sus procesos de gestión.
    <br />
    <br />
    Brindamos Beex a 3eriza, aplicando los siguientes procesos que optimizan su
    gestión diaria:
  </span>
)

const texttwo = (
  <span>
    “Venimos trabajando con 3eriza cinco años, en los cuáles hemos compartido
    feedback que han sumado a sus operaciones y a nosotros como empresa de
    tecnología. Estamos felices de tener un cliente con tan buena
    trayectoria y reputación en el mercado nacional”
  </span>
)

const data = {
  intro: {
    image: Eriza,
    textbody: textintro,
    classlogo: "customerEriza",
  },
  description: {
    textbody: text,
    photo: FotoRimac,
    class:"section-description"
  },
  cards: [
    {
      title: "CRM",
      img: crm,
      info:
        "Registro total de clientes y productos en nuestro CRM, para registrar los pasos del viaje del cliente, desde el contacto hasta el backoffice.",
    },
    {
      title: "Llamadas",
      img: llamadas,
      info:
        "Marcaciones progresivas y predictivas para llegar a mas clientes en sus horarios preferidos, con esto, aumentan la contactabilidad y las chances de cerrar las ventas de sus productos.",
    },
    {
      title: "Backoffice",
      img: backoffice,
      info:
        "Módulos de calidad para escuchar y auditar las ventas realizadas en tiempo real, ya que cuentan con reglas claras de negocio, que se cumplen en los diferentes apartados de nuestros módulos validadores.",
    },
    {
      title: "Calidad",
      img: calidad,
      info:
        "Módulo y procesos de validación de ventas, pudiendo transferir en tiempo real las diferentes interacciones del cliente para que sean validadas por agentes especializados en estos skillls.",
    },
  ],
  descriptiontwo: {
    textbody: texttwo,
  },
}

export default data
